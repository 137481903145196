import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import MainPage from "../core/pages/main-page";
import FeesPage from "../core/pages/fees-page";
import ServicesPage from "../core/pages/services-page";
import OpenAccPage from "../core/pages/open-account-page";
import ContactPage from "../core/pages/contact-page";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact>
          <MainPage />
        </Route>
        <Route path="/fees">
          <FeesPage />
        </Route>
        <Route path="/services/:service">
          <ServicesPage />
        </Route>
        <Route path="/open-account">
          <OpenAccPage />
        </Route>
        <Route path="/contacts">
          <ContactPage />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
