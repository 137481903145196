import React from "react";

import { ContentBlock } from "../../../components/styled";

function CorporateBanking() {
  return (
    <ContentBlock>
      <h1 style={{ color: "#27336f", fontWeight: "normal" }}>
        CORPORATE BANKING
      </h1>
      <p>
        We know, in most cases, your business cannot be operated if your company
        has no corporate bank account. Despite what business objectives you
        have, setting up a proper company bank account is an integral part of
        any business plan. Different countries have their own local banking
        laws, and each bank has different account types with corresponding
        procedures and required documents to follow. The whole process would
        cost you several months and missed potential business opportunities
        before you know what works best.
      </p>
      <p>
        We could help to cut your waiting time to just a few weeks or less, and
        only cost you a small fraction of what you might have otherwise spent by
        opening account yourself. AngloAmerican International Bancorp supporting
        its customers to open bank accounts in the Jurisdiction of SADR. One of
        our main advantages is that the whole bank account process could be done
        online without a signatory travel.
      </p>
      <h2 style={{ textAlign: "center" }}>
        Offshore corporate banking benefits:
      </h2>
      <ul>
        <li>Operating bank accounts for your offshore company.</li>
        <li>Access to global markets for trading and investment purposes.</li>
        <li>
          Super easy and convenient to make international payments with no
          currency control.
        </li>
        <li>
          Support multi-currency accounts including USD, EUR, GBP, SGD, HKD,
          AUD, etc.
        </li>
        <li>Protect your assets.</li>
        <li>High level of data confidentiality.</li>
        <li>
          Facilitate transactions with 24/7 internet banking, prepaid cards.
        </li>
      </ul>
      <h2 style={{ textAlign: "center" }}>
        How long would it take to open my personal bank account?
      </h2>
      <p>
        Depending on individual bank procedures, the timeframe varies and
        normally takes <b>1 week</b> or more since our receipt of all duly
        prepared documents
      </p>
      <h2 style={{ textAlign: "center" }}>Offshore Bank Account Details</h2>
      <ul>
        <li>
          Opening an offshore account in AngloAmerican International Bancorp
          requires a deposit of (…) USD
        </li>
        <li>No in-person requirements</li>
        <li>Accounts can be held in several different currencies</li>
        <li>Credit cards and internet banking services included</li>
      </ul>
      <h2 style={{ textAlign: "center" }}>
        Personal Bank Account Requirements
      </h2>
      <ul>
        <li>Notarized copy of passport</li>
        <li>Bank and professional reference letter</li>
        <li>
          Proof of physical address (copy of utility bill, bank statement)
        </li>
        <li>Incorporation documents</li>
        <li>Business plan</li>
      </ul>
      <h2 style={{ textAlign: "center" }}>
        Personal Bank Account opening procedure:
      </h2>
      <ol className="ol-brackets">
        <li>Fill up the Personal Bank Account Application</li>
        <li>Send a Personal Bank Account Application via email to: …</li>
        <li>Provide the required documents</li>
        <li>
          After verification by the bank send a payment for the Personal Bank
          Account opening fees
        </li>
        <li>
          Personal Bank Account will be opened within seven (7) banking days
          after we have received and confirmed the Personal Bank Account opening
          fees
        </li>
      </ol>
    </ContentBlock>
  );
}

export default CorporateBanking;
