import React from "react";
import Header from "../../components/header";
import { PageWrapper, ContentBlock } from "../../components/styled";
import ContactForm from "../../components/contact-form";

function ContactPage() {
  return (
    <PageWrapper>
      <Header></Header>
      <h2 style={{ marginTop: "90px" }}>Leave us an enquiry</h2>
      <ContactForm></ContactForm>
      <ContentBlock>
        <h2>HERE IS OUR CONTACT INFORMATION</h2>
        <ul
          style={{
            width: "73%",
            fontWeight: "bold",
            listStyleType: "none",
          }}
        >
          <li>Phone:</li>
          <li>E-mail:</li>
          <li>Skype:</li>
          <li>Address:</li>
          <li>Social media:</li>
        </ul>
        <p>
          AngloAmerican Interbantional Bancorp’s license number: N 161 20/AABL
        </p>
      </ContentBlock>
    </PageWrapper>
  );
}

export default ContactPage;
