import React, { useState } from "react";
import { ContactButton } from "../styled";
import { FormContainer, FormInput, FormTextarea, FormWrapper } from "./styles";
import { API } from "../../../API/API";

const ContactForm = () => {
  const [sendUserData, setSendUserData] = useState({
    email: "",
    message: "",
    fullName: "",
    subject: "",
  });
  const [message, setMessage] = useState("");
  const sendMessage = async () => {
    const SendDto = {
      project: "angloAmericanBank",
      subject: sendUserData.subject,
      data: {
        email: sendUserData.email,
        fullName: sendUserData.email,
        message: sendUserData.email,
      },
    };

    if (
      SendDto.data.email.length !== 0 ||
      SendDto.data.fullName.length !== 0 ||
      SendDto.data.message.length !== 0
    ) {
      await API.sendUserData(SendDto)
        .then((res) => {
          console.log(res);
          setMessage("Your message was successfully sent");
          setSendUserData({
            email: "",
            message: "",
            subject: "",
            fullName: "",
          });
        })
        .catch((error) => {
          setMessage("Oops, something went wrong!");
          console.log(error);
        });
    } else {
      setMessage("All fields must be filled");
      console.log("error");
    }
  };
  return (
    <FormWrapper>
      <FormContainer>
        <FormInput
          style={{ width: "40%" }}
          placeholder="Full name*"
          maxLength={60}
          value={sendUserData.fullName}
          onChange={(e) => {
            setSendUserData({
              ...sendUserData,
              fullName: e.currentTarget.value,
            });
          }}
        />
        <FormInput
          style={{ width: "40%" }}
          placeholder="Email*"
          maxLength={60}
          value={sendUserData.email}
          onChange={(e) => {
            setSendUserData({
              ...sendUserData,
              email: e.currentTarget.value,
            });
          }}
        />
        <FormInput
          style={{ width: "90%" }}
          placeholder="Subject*"
          value={sendUserData.subject}
          onChange={(e) => {
            setSendUserData({
              ...sendUserData,
              subject: e.currentTarget.value,
            });
          }}
        />
        <FormTextarea
          placeholder="Message*"
          value={sendUserData.message}
          onChange={(e) => {
            setSendUserData({
              ...sendUserData,
              message: e.currentTarget.value,
            });
          }}
        />
      </FormContainer>
      <div>{message}</div>
      <ContactButton
        style={{ alignSelf: "center", margin: "30px 0 30px 0" }}
        onClick={() => {
          sendMessage();
        }}
      >
        Contact us
      </ContactButton>
    </FormWrapper>
  );
};

export default ContactForm;
