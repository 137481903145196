import React, { useMemo } from "react";
import Header from "../../components/header";
import PhotoBlock from "../../components/photo-block";
import { PageWrapper } from "../../components/styled";
import ContactForm from "../../components/contact-form";
import { useParams } from "react-router";
import CorporateBanking from "./components/CorporateBanking";
import PersonalBanking from "./components/PersonalBanking";
import CryptocurrencyAccount from "./components/CryptocurrencyAcc";
import DebitCard from "./components/DebitCards";

function ServicesPage() {
  const params = useParams();

  const chosenService = useMemo(() => {
    switch (params.service) {
      case "personal": {
        return <PersonalBanking />;
      }
      case "corporate": {
        return <CorporateBanking />;
      }
      case "crypto": {
        return <CryptocurrencyAccount />;
      }
      case "card": {
        return <DebitCard />;
      }
    }
  }, []);

  return (
    <PageWrapper>
      <Header></Header>
      <PhotoBlock isMainPage={false}></PhotoBlock>
      {chosenService}
      <div
        style={{
          width: "100%",
          textAlign: "center",
          fontSize: "25px",
          marginBottom: "20px",
        }}
      >
        <b>
          AngloAmerican International Bancorp will help you to protect your
          assets!
        </b>
        <br />
        <br />
        Have a question?
        <br />
        Fill up the form below, we will answer as soon as possible
      </div>
      <ContactForm></ContactForm>
    </PageWrapper>
  );
}

export default ServicesPage;
