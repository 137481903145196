import React from "react";
import Header from "../../components/header";
import PhotoBlock from "../../components/photo-block";
import { PageWrapper, ContentBlock } from "../../components/styled";
import ContactForm from "../../components/contact-form";

function OpenAccPage() {
  return (
    <PageWrapper>
      <Header></Header>
      <PhotoBlock isMainPage={false}></PhotoBlock>
      <ContentBlock>
        <h1
          style={{
            color: "#27336f",
            textAlign: "center",
          }}
        >
          Open Your Own Bank Account
          <br />
          With AngloAmerican International Bancorp
        </h1>
        <p>
          The advent of the information revolution has spawned a much greater
          choice in banking services. More particularly the option of Online
          Banking now means you can do your banking business in an environment
          or country that best meets your needs, at a time convenient to you.
        </p>
        <p>
          There are several good reasons as to why you would want to consider
          setting up an Offshore Account. Those reasons include:
        </p>
        <ul style={{ width: "60%" }}>
          <li>Confidentiality</li>
          <li>Operating flexibility</li>
          <li>Greater choice of investment products & services</li>
          <li>Services tailored to the needs of International Business</li>
          <li>Mitigate against currency movement risk</li>
          <li>Tax Savings</li>
          <li>Stability & security</li>
        </ul>
        <p style={{ width: "65%" }}>
          For more information on how you can benefit from setting up an
          AngloAmerican Bank account please contact us
        </p>
        <ul style={{ width: "60%" }}>
          <li>Phone</li>
          <li>Skype</li>
          <li>Email</li>
        </ul>
      </ContentBlock>
      <ContactForm></ContactForm>
    </PageWrapper>
  );
}

export default OpenAccPage;
