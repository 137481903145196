import styled from "styled-components";

export const FormWrapper = styled.div`
  width: 60%;
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;
export const FormContainer = styled.div`
  width: 60%;
  height: 400px;
  min-width: 300px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  background: #27336f;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
`;
export const FormInput = styled.input`
  height: 50px;
  border-radius: 30px;
  border: none;
  outline: none;
  font-size: 18px;
  padding-left: 10px;
  ::placeholder {
    color: black;
  }
`;
export const FormTextarea = styled.textarea`
  height: 150px;
  width: 90%;
  border-radius: 30px;
  font-family: "Arial";
  border: none;
  outline: none;
  font-size: 18px;
  padding-left: 10px;
  padding-top: 15px;
  resize: none;
  ::placeholder {
    color: black;
  }
`;
