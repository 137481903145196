import React from "react";
import {
  HeaderWrapper,
  HeaderLinksContainer,
  HeaderLink,
  HamburgerMenu,
} from "./styles";
import logo from "../../../assets/logo.svg";
import { useLocation } from "react-router";

function Header() {
  const location = useLocation();

  return (
    <HeaderWrapper>
      <div>
        <img src={logo} alt="logo"></img>
        <HeaderLinksContainer>
          <HeaderLink href="/" isChosen={location.pathname === "/"}>
            About Us
          </HeaderLink>
          <div className="dropdown">
            <HeaderLink class="dropbtn">Our services</HeaderLink>
            <div className="dropdown-content">
              <a href="/services/personal">Personal banking</a>
              <a href="/services/corporate">Corporate banking</a>
              <a href="/services/crypto">Cryptocurrency account</a>
              <a href="/services/card">Prepaid VISA debit cards</a>
            </div>
          </div>
          <HeaderLink
            href="/open-account"
            isChosen={location.pathname === "/open-account"}
          >
            Open account
          </HeaderLink>
          <HeaderLink href="/fees" isChosen={location.pathname === "/fees"}>
            Fees
          </HeaderLink>
          <HeaderLink
            href="/contacts"
            isChosen={location.pathname === "/contacts"}
          >
            Contact us
          </HeaderLink>
        </HeaderLinksContainer>
        <HamburgerMenu>
          <input type="checkbox" />

          <span></span>
          <span></span>
          <span></span>

          <ul id="menu">
            <a href="/">
              <li>About Us</li>
            </a>
            <a>
              <li>Our services</li>
              <ul style={{ listStyleType: "none" }}>
                <a href="/services/personal">
                  <li>Personal banking</li>
                </a>
                <a href="/services/corporate">
                  <li>Corporate banking</li>
                </a>
                <a href="/services/crypto">
                  <li>Cryptocurrency account</li>
                </a>
                <a href="/services/card">
                  <li>Prepaid VISA debit cards</li>
                </a>
              </ul>
            </a>
            <a href="/open-account">
              <li>Open account</li>
            </a>
            <a href="/fees">
              <li>Fees</li>
            </a>
            <a href="/contacts">
              <li>Contact us</li>
            </a>
          </ul>
        </HamburgerMenu>
      </div>
    </HeaderWrapper>
  );
}

export default Header;
