import React from "react";
import Header from "../../components/header";
import PhotoBlock from "../../components/photo-block";
import { PageWrapper, Table } from "../../components/styled";
import ContactForm from "../../components/contact-form";

function FeesPage() {
  return (
    <PageWrapper>
      <Header></Header>
      <PhotoBlock isMainPage={false}></PhotoBlock>
      <h1 style={{ color: "#27336f", fontWeight: "normal" }}>
        AngloAmerican International Bancorp FEES
      </h1>
      <Table style={{ width: "42%", marginBottom: "50px" }}>
        <colgroup>
          <col span="1" style={{ width: "50%" }} />
          <col span="1" style={{ width: "50%", textAlign: "center" }} />
        </colgroup>
        <tbody>
          <tr>
            <th>SERVICE</th>
            <th>COST</th>
          </tr>
          <tr>
            <td>
              Personal bank account opening
              <br />
              Non-refundable fee
            </td>
            <td>2500 USD</td>
          </tr>
          <tr>
            <td>
              Corporate bank account
              <br />
              Opening non-refundable fee
            </td>
            <td>3000 USD</td>
          </tr>
          <tr>
            <td>
              Incoming swift or iban wire
              <br />
              transfer deposit{" "}
            </td>
            <td>0.5 % (min 50 USD)</td>
          </tr>
          <tr>
            <td>
              Outgoing swift
              <br />
              or iban wire transfer
            </td>
            <td>0.5 (min 50 USD)</td>
          </tr>
          <tr>
            <td>
              Cryprocurrency account
              <br />
              Monthly fee
            </td>
            <td>30 USD</td>
          </tr>
          <tr>
            <td>Incoming cryptocurrency deposit</td>
            <td>1 %</td>
          </tr>
          <tr>
            <td>Outgoing cryptocurrency transfer </td>
            <td>1 %</td>
          </tr>
          <tr>
            <td>Prepaid VISA Card </td>
            <td> FREE</td>
          </tr>
          <tr>
            <td>Cash withdrawal and delivery </td>
            <td>1.5 %</td>
          </tr>
        </tbody>
      </Table>
      <div
        style={{
          textAlign: "center",
          fontSize: "25px",
          marginBottom: "20px",
        }}
      >
        <b>
          AngloAmerican International Bancorp will help you to protect your
          assets!
        </b>
        <br />
        <br />
        Have a question?
        <br />
        Fill up the form below, we will answer as soon as possible
      </div>
      <ContactForm></ContactForm>
    </PageWrapper>
  );
}

export default FeesPage;
