import styled from "styled-components";

export const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
  position: fixed;
  background-color: white;
  & > div {
    width: 60%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  } 

  @media (max-width: 1280px) {
    & > div {
      width: 100%;
    } 
`;
export const HeaderLinksContainer = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 50px;

  @media (max-width: 1000px) {
    display: none;
  }

  .dropdown {
    height: inherit;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    top: 80%;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .dropdown-content a {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }

  .dropdown-content a:hover {
    background-color: #ddd;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }
`;
export const HeaderLink = styled.a`
  text-decoration: none;
  position: relative;
  color: #27336f;
  font-size: 20px;
  cursor: pointer;
  :hover {
    ::after {
      width: 100%;
      transition: 0.5s ease all;
      left: 1%;
    }
  }
  ::after {
    ${(props) => (props.isChosen ? "width: 100%;" : "width: 0%;")}
    content: "";
    position: absolute;
    transition: 0.4s ease-out all 0.1s;
    background-color: #27336f;
    height: 2px !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    top: 95%;
    left: 1%;
  }
`;

export const HamburgerMenu = styled.div`
  display: none;
  position: relative;

  @media (max-width: 1000px) {
    display: block;
  }

  z-index: 1;

  -webkit-user-select: none;
  user-select: none;
  & > #menu a {
    text-decoration: none;
    color: #27336f;
    outline: none;
  }

  & > input {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    left: -5px;

    cursor: pointer;

    opacity: 0;
    z-index: 2;
    -webkit-touch-callout: none;
  }

  & > span {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;

    background: #000000;
    border-radius: 3px;

    z-index: 1;

    transform-origin: 4px 0px;

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  }

  & > span:first-child {
    transform-origin: 0% 0%;
  }

  & > span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }

  & > input:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);
    background: #232323;
  }

  & > input:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  & > input:checked ~ span:nth-last-child(2) {
    transform: rotate(-45deg) translate(0, -1px);
  }

  & > #menu {
    position: absolute;
    width: 400px;
    padding: 50px 10px 50px 25px;
    margin-left: 300px;
    background: #ededed;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;

    transition: margin-left 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  }

  & > #menu li {
    padding: 10px 0;
    font-size: 22px;
  }

  & > input:checked ~ ul {
    transform: none;
  }

  & > input:checked ~ #menu {
    margin-left: -240px;
  }
`;
