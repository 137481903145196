import React from "react";
import { LabelContainer, PhotoBlockContainer } from "./styles";
import PropTypes from "prop-types";
import { ContactButton } from "../styled";
import { useHistory } from "react-router";

function PhotoBlock({ isMainPage }) {
  const history = useHistory();

  return (
    <PhotoBlockContainer isMainPage={isMainPage}>
      {isMainPage ? (
        <LabelContainer>
          <span style={{ textAlign: "center" }}>
            AngloAmerican International Bancorp
          </span>
          <ContactButton onClick={() => history.push("/contacts")}>
            Contact Us
          </ContactButton>
        </LabelContainer>
      ) : (
        ""
      )}
    </PhotoBlockContainer>
  );
}

export default PhotoBlock;

PhotoBlock.propTypes = {
  isMainPage: PropTypes.bool,
};
