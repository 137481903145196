import React from "react";

import { ContentBlock } from "../../../components/styled";

function DebitCard() {
  return (
    <ContentBlock>
      <h1 style={{ color: "#27336f", fontWeight: "normal" }}>
        PREPAID VISA DEBIT CARDS
      </h1>
      <p>
        A prepaid card is just like a normal credit or debit card. It carries
        all the normal card features, such as a card number, expiry date and
        security code, and can be used at most places that accept a conventional
        visa card. The most commonly used prepaid cards are issued under brands
        such as MasterCard or Visa. We offer Visa prepaid cards.
      </p>
      <p>
        A prepaid card is not linked to one of your bank account (like a normal
        debit card or an atm card). Most issuers of prepaid cards are not banks
        but financial companies which have an agreement with MasterCard have or
        Visas to issue their own prepaid cards. Formally there is no bank
        account issue at your name but a prepaid card account which you can load
        and check online.
      </p>
      <p>
        The AngloAmerican International Bancorp Prepaid Visa card is a
        reloadable card that makes everyday purchases fast, easy, convenient and
        secure.
      </p>
      <h2 style={{ textAlign: "center" }}>
        Key Features of the Bank Card Include:
      </h2>
      <ul>
        <li>Available to both personal and corporate accounts holders</li>
        <li>The card can be preloaded with up to USD $ (…)</li>
        <li>Can be used anywhere Worldwide that displays the Visa card logo</li>
        <li>Cash withdrawals available from ATM machines worldwide</li>
        <li>Enhanced 24/7 Online Services</li>
        <li>Self Card Activation/PIN</li>
        <li>change/forgotten or lost PIN</li>
        <li>24/7 Live operator Help</li>
        <li>Transfers Between Card Holders</li>
      </ul>
    </ContentBlock>
  );
}

export default DebitCard;
