import axios from "axios";

const settings = {
  headers: {
    "Content-Type": "application/json",
  },
};

const instance = axios.create({
  baseURL: "https://assembly.tehnik.tech/",
  ...settings,
});

export const API = {
  sendUserData(data) {
    return instance.post("api/send_form/from", data, settings);
  },
};
