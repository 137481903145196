import React from "react";

import { ContentBlock } from "../../../components/styled";

function CryptocurrencyAccount() {
  return (
    <ContentBlock>
      <h1 style={{ color: "#27336f", fontWeight: "normal" }}>
        CRYPTOCURRENCY ACCOUNT
      </h1>
      <p>
        There have been a lot of buzzes about cryptocurrencies. A large number
        of entrepreneurs, start-ups, and SMEs have been taking interest in the
        cryptocurrency world, and this booming trend seems to not stop there.
        So, what is it? A cryptocurrency is simply understood as a virtual
        currency to act as a medium of exchange. It is structured by using
        cryptography to secure financial transactions of the coin ownership,
        verify the transfer of assets and control the creation of extra units.
      </p>
      <p>
        As opposed to cryptocurrency, “fiat currency” is a familiar term. Fiat
        currencies – US dollar, Euro, Pound for example, are used in physical
        transactions, and would be all issued and managed by the central bank.
      </p>
      <p>
        Сryptocurrency is a form of payment that can be exchanged online for
        goods and services. Many companies have issued their own currencies,
        often called tokens, and these can be traded specifically for the good
        or service that the company provides. Think of them as you would arcade
        tokens or casino chips. You’ll need to exchange real currency for the
        cryptocurrency to access the good or service.
      </p>
      <p>
        Cryptocurrencies work using a technology called blockchain. Blockchain
        is actually a digital ledger or a decentralized network including a
        large number of distributed computers to manage and record all
        transactions. It makes cryptocurrencies not depend on a central
        server/computer like central banking systems. Part of the appeal of this
        technology is its security.
      </p>
      <h2 style={{ textAlign: "center" }}>
        AS PERSONAL BANK ACCOUNT OWNER YOU CAN:
      </h2>
      <ol>
        <li>
          <b>
            <i>Greater privacy & asset protection</i>
          </b>
        </li>
        <p>
          Privacy maximization is the top priority that draws crypto-based
          businesses to go offshore. It appears to be common that if the bitcoin
          and its owners are more separate, there will be stronger layers of
          protection for this kind of digital assets. For this reason, keeping
          bitcoin and other cryptocurrencies in association with an
          international business company seems to bring best of both worlds.
          Other offshore management vehicles that are also widely preferred
          include forming a limited liability company or a trust for offshore
          asset protection.
        </p>
        <li>
          <b>
            <i>Greater privacy & asset protection</i>
          </b>
        </li>
        <p>
          Are you finding a solution for tax savings in crypto? Moving offshore
          is a good idea. By having your coins stored by offshore companies, you
          may relieve the burden on a set of tax obligations as imposed in your
          home country. However, it depends on which jurisdiction you wish to
          incorporate your offshore company for cryptocurrency that the savings
          can be more or less significant proportions.
        </p>
      </ol>
      <p>
        By having your coins stored by offshore companies, you may relieve the
        burden on a set of tax obligations as imposed in your home country.
        However, it depends on which jurisdiction you wish to incorporate your
        offshore company for cryptocurrency that the savings can be more or less
        significant proportions.
      </p>
      <h1 style={{ textAlign: "center" }}>
        At AngloAmerican International Bancorp we will help you to get
        acquainted with the new world of cyptocurrency!
      </h1>
    </ContentBlock>
  );
}

export default CryptocurrencyAccount;
