import React from "react";

import { ContentBlock } from "../../../components/styled";

function PersonalBanking() {
  return (
    <ContentBlock>
      <h1 style={{ color: "#27336f", fontWeight: "normal" }}>
        PERSONAL BANKING
      </h1>
      <p>
        If your aim is to grow your business, increase your income and protect
        your money in a central location within a stable economy, our banking
        service can help you, wherever your location. You can store your money
        in one secure place and be assured your funds are safe, wherever you may
        go in the world.
      </p>
      <p>
        Having a personal bank account offers plenty of benefits; the most
        valuable being a private account that enables you to manage payments
        efficiently throughout the world. If everyday personal expenses and
        monthly payments are creating distress, these will be minimised with a
        personal bank account, which is created to facilitate independent
        financial transactions. It becomes easy, for example, to transfer money
        to and from different accounts, buy at retail outlets, and pay bills
        online etc. You can manage all these operations in different currencies
        and locations throughout the world.
      </p>
      <h2 style={{ textAlign: "center" }}>
        AS PERSONAL BANK ACCOUNT OWNER YOU CAN:
      </h2>
      <ul>
        <li>have multi currency account in EUR, USD or GBP</li>
        <li>simply manage your account online</li>
        <li>make payments worldwide in a safe way</li>
        <li>convert foreign currencies in less than no time</li>
        <li>get the customer support 24/7</li>
        <li>instantly withdraw cash from any international ATM</li>
        <li>open the debit card in €, $ or £</li>
        <li>make both online and cashless payments</li>
      </ul>
      <h2 style={{ textAlign: "center" }}>
        Offshore Personal Banking Benefits:
      </h2>
      <ol>
        <li>Higher Returns In International Investments</li>
        <li>Economic And Political Stability</li>
        <li>Generate Higher Interest Rates</li>
        <li>Foreign Banking Systems Offer Security</li>
        <li>Diversify Your Wealth</li>
        <li>Higher Liquidity</li>
        <li>Hold Multiple Currencies </li>
        <li>Asset Protection</li>
        <li>Account Confidentiality</li>
      </ol>
      <h2 style={{ textAlign: "center" }}>
        How long would it take to open my personal bank account?
      </h2>
      <h2 style={{ textAlign: "center" }}>
        Depending on individual bank procedures, the timeframe varies and
        normally takes 1 week or more since our receipt of all duly prepared
        documents
      </h2>
      <h2 style={{ textAlign: "center" }}>Offshore Bank Account Details</h2>
      <ul>
        <li>
          Opening an offshore account in AngloAmerican International Bancorp
          requires a deposit of (…) USD
        </li>
        <li>No in-person requirements</li>
        <li>Accounts can be held in several different currencies</li>
        <li>Credit cards and internet banking services included</li>
      </ul>
      <h2 style={{ textAlign: "center" }}>
        Personal Bank Account Requirements
      </h2>
      <ul>
        <li>Copy of passport</li>
        <li>Proof of address (utility bill)</li>
        <li>Professional Reference</li>
        <li>Proof of Funds</li>
        <li>Letter from your Bank</li>
        <li>Business Plan</li>
        <li>CV</li>
      </ul>
      <h2 style={{ textAlign: "center" }}>
        Personal Bank Account opening procedure:
      </h2>
      <ol className="ol-brackets">
        <li>Fill up the Personal Bank Account Application</li>
        <li>Send a Personal Bank Account Application via email to: …</li>
        <li>Provide the required documents</li>
        <li>
          After verification by the bank send a payment for the Personal Bank
          Account opening fees
        </li>
        <li>
          Personal Bank Account will be opened within seven (7) banking days
          after we have received and confirmed the Personal Bank Account opening
          fees
        </li>
      </ol>
    </ContentBlock>
  );
}

export default PersonalBanking;
