import styled from "styled-components";
import background from "../../../assets/background.png";

export const PhotoBlockContainer = styled.div`
  ${(props) =>
    props.isMainPage ? "width: 100%; height:50vw;" : "width: 60%; height:30vw;"}
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${background});
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 65px;
`;

export const LabelContainer = styled.div`
  width: 60%;
  height: 42%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  & > span {
    font-weight: bold;
    font-size: 48px;
  }

  @media (max-width: 1000px) {
    width: 85%;
    height: 80%;
    & > span {
      font-size: 35px;
    }
  }
  @media (max-width: 425px) {
    & > span {
      font-size: 20px;
    }
  }
`;
