import React from "react";
import ContactForm from "../../components/contact-form";
import Header from "../../components/header";
import PhotoBlock from "../../components/photo-block";
import { PageWrapper, ContentBlock } from "../../components/styled";
import redCheck from "../../../assets/red-check-icon.svg";

function MainPage() {
  ("main");
  return (
    <PageWrapper>
      <Header></Header>
      <PhotoBlock isMainPage={true}></PhotoBlock>
      <ContentBlock>
        <h1 style={{ color: "#27336f", fontWeight: "normal" }}>
          WHAT IS OFFSHORE BANKING?
        </h1>
        <p>
          {" "}
          An offshore bank account refers to the use of banking services in a
          foreign jurisdiction; where the individual resides outside the
          jurisdiction where the bank is located. If you are a UK person with a
          bank in the U.S. for example, you are using offshore banking. The term
          offshore is really just used to separate domestic and foreign banks.
        </p>
        <p>
          Offshore banks are regulated by laws defined specifically within each
          jurisdiction and exist as per their regulations. Each banking entity
          operates under a Banking License governed by that specific state or
          governmental authority. To exist as a banking institution it must also
          comply with international banking standards and regulations that must
          be meet regularly to continue dealing with correspondent banks. An
          offshore bank operates in many ways like a traditional bank. An
          individual or corporate body can open an offshore account with a
          foreign bank outside of the country of their residence and have access
          to the same services such as deposits, payments, withdrawals, and
          online transactions. The differences, however, are that offshore
          accounts benefit from a number of advantages that come from being
          located in international offshore jurisdictions.
        </p>
        <p>
          Offshore Banking in a foreign jurisdiction isn’t as distant and
          exotic-sounding as the name sounds. By some estimates, there are
          nearly 3 million people in the United States who have foreign offshore
          bank accounts. So it&apos;s definitely catching on, but that&apos;s
          only 1% of the population, so it still hasn&apos;t caught fire. Many
          see the value in having a back-up-plan, a Plan B in case something
          goes wrong, but somehow using an alternative banking apparatus is
          still not seen as a solution for tomorrow&apos;s what if&apos;s.
          Global offshore banking gives the ability to ensure your future. You
          become more autonomous in your ability to exist outside the confines
          of any one system:
        </p>
        <ul>
          <li>if that economic system collapse (Venezuela)</li>
          <li>if your domestic assets get frozen (Cyprus)</li>
          <li>
            or if you get slapped with a lawsuit (1 in every 2 Americans)
            <br /> you will have your offshore back-up-plan already in place.
          </li>
        </ul>
        <p>
          Having an offshore bank account isn’t what the headline say it is. Its
          not about tax evasion, secret stashes of cash, or shady dealings, its
          about going to a country that is economically sound, politically
          stable, with a banking environment that is fully functional, where you
          are able to reap the benefits of establishing multiple financially
          secure asset structures.
        </p>
        <p>
          The financial crises of 2008 showed how fragile the traditional
          banking system really is. In the US alone over 500 banks collapsed.
          Many regular individuals lost their life savings, investments,
          retirements, and mortgagees. Over 10 million people in the United
          States alone lost their homes. Europe did not fare much better than
          the United States during the global banking crises as Cyprus, Greece,
          Hungary, all had massive banking failures, with governments ending up
          taking large swaths of cash from their citizens!
        </p>
        <ul>
          <li>Cyprus government taxed depositors,</li>
          <li>Greece imposed severe capital controls; and</li>
          <li>Hungary nearly ended private pensions.</li>
        </ul>
        <p>
          {" "}
          If a bank has low-cash reserves and engages in risky investments, all
          it takes is a bump in the economy to send those investments tumbling,
          leaving the bank unable to cover deposits. This is part of what
          happened in the sub-prime real estate mortgages in the United States.
          Banks were unable to cover their losses because they had lent money to
          people who couldn’t afford it, and when individuals defaulted on their
          payments, the banks found themselves overextended.
        </p>
        <h1 style={{ color: "#27336f", fontWeight: "normal" }}>WHY US?</h1>
        <p>
          {" "}
          Having offshore bank accounts is a perfect diversification strategy
          that gives you much-needed access to overseas banking structures that
          are much more aligned with safe and secure financial practices. There
          is too much to lose by having all your funds stored in the banks that
          engage in dangerous financial practices - as they are gambling with
          YOUR MONEY!
        </p>
        <p>
          AngloAmerican International Bancorp specializes in opening offshore
          bank and cryptocurrency accounts. We work at one of the most
          perspective jurisdiction that has a lot of benefits for you and for
          purposes of your company. AngloAmerican International Bancorp’s
          services differ from your local domestic services primarily because of
          SADR jurisdiction’s banking laws which allow for broader use of
          services with fewer regulations.
          <br />
          Offshore banking offers a wealth of opportunities that offer insurance
          against the negligence of an irresponsible system.
        </p>
        <h2 style={{ textAlign: "center" }}>
          Here are 6 Advantages explaining why you need an AngloAmerican
          International’s offshore bank account.
        </h2>
        <div style={{ textIndent: "40px", fontSize: "25px" }}>
          <p>
            1. Don’t put all your eggs into one Basket, Diversify Your Wealth
            <br />• Protect your wealth from domestic political or economic
            fluctuations by diversifying your assets across jurisdictions and
            accounts. This allows for a stable long-term savings portfolio that
            will not be affected by the downturn of just one economy.
          </p>
          <p>
            2. More Stable Banking Systems in Foreign Jurisdictions <br />• Out
            of the worlds top 50 banks only 3 were in the US.
          </p>
          <p>
            3. Higher Returns in Global Investment Funds <br />• Offshore
            foreign accounts give you greater access to banking and financial
            services typically not available to domestic banks.
          </p>
          <p>
            4. Hold Multiple Currencies <br />• Offshore bank allows for upwards
            of 3 and more different currencies so that your assets aren&apos;t
            tied to a single country and its fluctuating currency.
          </p>
          <p>
            5. Asset Protection from unscrupulous lawsuits <br /> • Protect your
            assets from predators by having separate foreign banking laws to
            shield you from malicious parties.
          </p>
          <p>
            6. Greater Levels of Banking Privacy <br />• Prevent your name and
            details to be easily associated with your assets by using an
            offshore company setup to hold the account. Although there is no
            such thing as absolute secrecy in this age, there are measures that
            can be effectively taken to make your assets more private and less
            open to the public gaze.
          </p>
        </div>
        <h2>Our benefits:</h2>
        <ul>
          <li style={{ listStyleImage: `url(${redCheck})` }}>
            Non-FATCA Bank
            <br />
            <br /> As of 2014, the United States&apos; Foreign Account Tax
            Compliance Act (FATCA) makes it very difficult for any U.S. citizens
            or residents to obtain any type of banking secrecy. This is due to
            the United States&apos; reporting obligations forcing foreign banks
            to send tax information regarding any assets held by a US citizen.
            If they refuse they risk losing their banking ties with American
            correspondent banks, being blacklisted and essentially cut-off from
            all American capital. U.S. citizens have for several years now been
            required to report their ownership of any foreign held account with
            deposits of more than US$10,000 through FBAR. The only thing that
            has changed is that now the United States government has made it
            much more difficult for individuals and their corresponding banks to
            get away with not reporting their foreign accounts. AngloAmerican
            International Bancorp is a non-FATCA bank, we work especially to
            protect business and assets of our clients.
          </li>
          <br />
          <li style={{ listStyleImage: `url(${redCheck})` }}>
            Not an OECD member
            <br />
            <br /> The jurisdiction of Western Sahara which The AngloAmerican
            International Bancorp works in is not a member of OECD.
          </li>
          <br />
          <li style={{ listStyleImage: `url(${redCheck})` }}>
            No CRS reports
            <br />
            <br /> For many other countries, there are other pieces of
            legislation drafted by the OECD known as the Common Reporting
            Standard (CRS) that have been widely enacted in an attempt to
            encourage the Automatic Exchange of Tax Information. These new
            pieces of legislation have changed the privacy of global banking, as
            a result, we can not recommend it to anyone who now tries to hide
            money away from the government.
          </li>
        </ul>
        <h1 style={{ color: "#27336f", fontWeight: "normal" }}>
          THE JURISDICTION OF WESTERN SAHARA, SADR
        </h1>
        <p>
          {" "}
          World economic downturn leads to the fact that every year it becomes
          more difficult to find a jurisdiction where you can conduct your
          business and not be charged with countless taxes.
        </p>
        <p>
          The SADR is recognized as one of the most rising world’s leading
          offshore finance centers established itself as an e-commerce center of
          excellence. Western Sahara, SADR has its own government, legislature,
          and company laws and operates with the same modern banking, insurance,
          and investment laws. The Central Reserve Authority of SADR is
          committed to the effective regulation and supervision of Western
          Sahara, SADR’s financial services sector by upholding internationally
          recognized standards of compliance, thereby positioning the
          jurisdiction as the preeminent financial center in the financial
          services arena. Establishing a business venture in the free zone of
          Western Sahara, SADR is an attractive option for foreign investors
          looking to tap into the huge potentials and excellent business
          environment offered by the SADR. Another important factor of offshore
          banking services in SADR jurisdiction is strict anonymity and
          confidentiality, which is highly appreciated by many investors and
          depositors.
        </p>
        <p>
          SADR is a new player on the world stage, that has created the best
          conditions for offshore business to ensure economic growth. We hope
          that you will use our services and appreciate all the advantages of
          having your business registered on the territory of SADR.
        </p>
      </ContentBlock>
      <div
        style={{
          textAlign: "center",
          fontSize: "25px",
          marginBottom: "20px",
        }}
      >
        <b>
          AngloAmerican International Bancorp will help you to protect your
          assets!
        </b>
        <br />
        <br />
        Have a question?
        <br />
        Fill up the form below, we will answer as soon as possible
      </div>
      <ContactForm></ContactForm>
    </PageWrapper>
  );
}

export default MainPage;
