import styled from "styled-components";

export const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
`;

export const ContactButton = styled.button`
  width: 135px;
  height: 45px;
  background: #27336f;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  border: none;
  color: white;
  cursor: pointer;
  outline: none;
  @media (max-width: 1000px) {
    width: 105px;
    height: 35px;
  }
  transition: background 0.5s;
  :hover {
    background: #1f339c;
  }
`;

export const ContentBlock = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1,
  h2 {
    text-align: center;
  }
  & > p {
    text-indent: 20px;
  }
  & > p,
  & > ul,
  & > ol {
    font-size: 25px;
  }

  .ol-brackets {
    counter-reset: list;
  }
  .ol-brackets > li {
    list-style: none;
  }
  .ol-brackets > li:before {
    content: counter(list) ") ";
    counter-increment: list;
  }

  @media (max-width: 1000px) {
    width: 80%;
  }
`;

export const Table = styled.table`
  border: 1px solid #000000;
  border-collapse: collapse;
  font-size: 25px;
  th {
    background-color: #27336f;
    padding: 0;
    color: white;
    box-sizing: border-box;
  }
  tr,
  td {
    border: 1px solid black;
  }
  td:last-child {
    text-align: center;
  }
`;
